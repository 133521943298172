<template>
  <div v-if="approval && !forceHide" class="flex flex-col justify-center items-center mr-3">
    <div v-if="displayLinks" class="w-full flex justify-between items-center text-muted mb-2">
      <template :key="`label-${step.id}`" v-for="step in approval.approvalSteps">
        <label class="text-2xs">
          {{ step.approvers[0].role }}
        </label>
      </template>
    </div>
    <div class="flex w-full justify-center items-center">
      <template :key="`checkbox-${step.id}`" v-for="(step, index) in approval.approvalSteps">
        <template
          v-if="
            (showCheckBox(step) && !hideApproved) ||
            (isApproved(step.status) && isApprover(step) && !hideApproved) ||
            displayLinks
          "
        >
          <Checkbox
            :value="approvedIds.includes(step.id) || isApproved(step.status)"
            @change="(val) => handleSelect(step, val)"
            :disabled="!showCheckBox(step) || isApproved(step.status)"
            :disabledMsg="
              isApproved(step.status) ? 'Item has been approved.' : 'Item needs to be approved.'
            "
            :toolTip="
              isApproved(step.status) ? 'Item has been approved.' : 'Item needs to be approved.'
            "
            ref="checkbox"
          />
          <div
            v-if="approval.approvalSteps.length - 1 !== index && displayLinks"
            class="w-6 h-0.5 bg-cool-gray-400"
            :class="{ approved: step.status === 'APPROVED' }"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, watch } from 'vue'
import Checkbox from '@/components/ui/Checkbox.vue'
import useApproval from '@/components/composables/Approval'
import useItemizedPayments from '@/components/composables/ItemizedPayments'
import eventBus from '@/eventBus'

const {
  approvedIds,
  approval: approvalRecord,
  addOrRemoveFromApprovedItems,
  showCheckBox,
  isApproved,
  isApprover
} = useApproval()

const { toggleFromItems } = useItemizedPayments()

const props = defineProps({
  item: {
    required: true
  },
  approval: {
    type: Object,
    default: () => {}
  },
  displayLinks: {
    type: Boolean,
    default: () => false
  },
  forceHide: {
    type: Boolean,
    default: () => false
  },
  hideApproved: {
    type: Boolean,
    default: () => false
  }
})

// reactive props
const item = toRef(props, 'item')
const itemApproval = toRef(props, 'approval')

const handleSelect = (step, val) => {
  addOrRemoveFromApprovedItems(step, val)
  toggleFromItems({
    item: item.value,
    selected: val
  })
  eventBus.$emit('three-way-approval-select', {
    item: item.value,
    approval: itemApproval.value
  })
}

watch(
  () => itemApproval.value,
  () => {
    if (itemApproval.value && Object.keys(itemApproval.value).length > 0) {
      approvalRecord.value = itemApproval.value
    }
  },
  {
    immediate: true
  }
)
</script>
